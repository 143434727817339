body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Muli";
  src: local("Muli"), url(./fonts/Muli-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Muli_SemiBold";
  src: local("Muli"), url(./fonts/Muli-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Muli_Bold";
  src: local("Muli"), url(./fonts/Muli-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Muli_Light";
  src: local("Muli"), url(./fonts/Muli-Light.ttf) format("truetype");
}

.main-container {
  background-color: #f9f9f9;
  margin: 0 96px 0;
}

@media (max-width: 1024px) {
  .main-container {
    margin: 0px 87px 0px;
  }
}
@media (max-width: 992px) {
  .main-container {
    margin: 0px;
  }
}

@media (max-width: 768px) {
  .main-container {
    margin: 0px;
  }
}
@media only screen and (max-width: 640px) {
}
